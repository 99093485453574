#orderMenuItem.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
}


#orderMenuItem.modal .label {
  display: flex;
  flex-flow: row;
  padding-top: .3rem;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

#orderMenuItem.modal .label span
{ 
  color: darkslategray;
  background: rgba(255, 255, 0, 0.645);
  width: min-content;
  white-space: nowrap;
  align-self: center;
  padding:.3rem;
  line-height: 1rem;
  border: .3px solid lightgray;
  border-radius: .7rem;
}

li.mate span.times {
  display: none;
}

li.mate.active span.times {
  display: unset;
  vertical-align: middle;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 1rem;
}

li.mate.active span.times.hidden {
  padding-left: 0rem;
  padding-right: 0rem;
  margin-right: 0rem;
}

.additionsBlock .list-group-item {
  background-color: #5cb85c;
  color: white;
}

.additionsBlock .btn-group-toggle label.btn,
.additionsBlock .list-group-item.optional {
  margin: 3px;
  box-shadow: 2px 2px 7px #449d44;
}

.additionsBlock .list-group-item.mandatory {
  margin: 3px;
  box-shadow: 2px 2px 7px darkred;
}

.additionsBlock .list-group-item.included {
  margin: 3px;
  box-shadow: 2px 2px 7px crimson;
}

.list-group.checked-list-box .additionsBlock .list-group-item.included:not(.active) {
  text-decoration: line-through;
}

.list-group.radio-list-box .list-group-item {
  font-size: 2rem;
  white-space: nowrap;
  width: unset;
  line-height: 1.2;
}
.list-group.radio-list-box input:not(:checked) + .list-group-item.included,
.list-group.checked-list-box .list-group-item.included:not(.active) {
  text-decoration: line-through;
  background-color: white !important;
  color: black;
}

.additionsBlock .list-group-item.optional.active .addition-label,
.additionsBlock .list-group-item.optional.active {
  color: white;
  background-color: blueviolet !important;
}

.additionsBlock .list-group-item.mandatory.active {
  background-color: darkred;
  color: white;
}

.list-group.radio-list-box .list-group-item.included {
  background-color: crimson !important;
  color: white;
}

.additionsBlock .list-group-item.included.active {
  background-color: crimson !important;
  color: white;
}

.list-group-item.active {
  border: unset !important;
}

.additionsBlock .btn-group-toggle label.btn,
.additionsBlock .list-group-item {
  background: white;
  color: black;
  border-width: 0rem;
  border-style: solid;
  flex-grow: 1;
  display: flex;
  padding: 0px;
  align-items: center;
  padding-left: 0rem;
  max-width: 100%;
  overflow-x: hidden;
}

.additionsBlock input[type="number"]::-webkit-outer-spin-button,
.additionsBlock input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.additionsBlock input[type="number"] {
  -moz-appearance: textfield;
}

.additionsBlock .list-group-item.hidden {
  display: none;
}

.additionsBlock .list-group-item.selected {
  border-color: steelblue;
  border-style: groove;
}

.additionsBlock .list-group-item.active {
  border-style: solid;
}

.additionsBlock li:not(.active) input,
.additionsBlock li:not(.selected) input {
  display: none;
}

.additionsBlock li:not(.selected) span.quantity[value="1"][quantity-type="pcs"],
.additionsBlock li:not(.active) span.quantity[value="1"][quantity-type="pcs"],
.additionsBlock li:not(.selected) span.quantity[value="1"][quantity-type="pcs"] + span,
.additionsBlock li:not(.active) span.quantity[value="1"][quantity-type="pcs"] + span,
.additionsBlock li.selected.active span.quantity {
  display: none;
}

.additionsBlock li:not(.active) span.quantity,
.additionsBlock li:not(.selected) span.quantity {
  margin-left: 0.5rem;
}

.additionsBlock .list-group-item.optional.active[v="0"] {
  background-image: blueviolet;
}

.additionsBlock .list-group-item.optional.active[v="0.5"] {
  background: cornflowerblue;
}

.additionsBlock .list-group-item.optional input {
  background-color: #91ca92;
}

div.additionsBlock {
  margin-left: unset;
  margin-right: unset;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column;
  flex-flow: row;
  margin-right: 30rem;
}

div.additionsBlock.wide {
  margin-right: 0rem;
  width: 100%;
  padding-right: 1rem;
}

div.additionsBlock label {
  width: 10rem;
}

div.additionsBlock ul.btn-group-toggle label {
  font-size: 2rem;
  white-space: nowrap;
  flex-shrink: 0;
  width: unset;
}
div.additionsBlock ul {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 0rem;
  padding: 0;
}

div#orderMenuItem #matesBlock ul li div {
  vertical-align: middle;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  align-items: center;
  flex-flow: column;
  display: flex;
  line-height: 1.7rem;
  height: 4.2rem;
  justify-content: center;
}

div#orderMenuItem .numpad {
  display: flex;
  flex-flow: row;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  position: fixed;
  width: 30rem;
  right: 1rem;
  bottom: 70px;
  flex-wrap: wrap;
}

#orderMenuItem .modal-footer {
  padding: 0;
}

#orderMenuItem .modal-footer button {
  padding: 0;
  height: 40px;
}

div#orderMenuItem .price-per-quantity {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  display: flex;
}

div#orderMenuItem .keyboard {
  position: sticky;
  left: 0;
  bottom: -5rem;
  margin-right: 30rem;
  background: white;
}

div#orderMenuItem .keyboardPlaceholder {
  position: fixed;
  bottom: 70px;
  width: calc(100% - 2rem);
}


li.mate img.updown-icon {
  /*background-color: #5cb85c;*/
  margin-right: 8px;
}

@media (min-width: 0rem) and (max-width: 487px) {
  div#orderMenuItem #matesBlock ul li div {
    line-height: 2.5rem;
    height: 6.2rem;
    min-width: 7rem;
  }

  div#orderMenuItem div#timingBlock > label,
  div#orderMenuItem div#quantityBlock > label,
  div#orderMenuItem div#commentBlock > label,
  div#orderMenuItem div.additionsBlock > label,
  div#orderMenuItem div#matesBlock > label {
    display: none;
  }

  .additionsBlock .list-group-item.optional {
    border: 2px solid lightgray;
    box-shadow: none;
  }

  div#orderMenuItem div#timingBlock {
    font-size: 2rem;
    margin-left: unset;
    margin-bottom: 2rem;
    display: flex;
  }

  div#orderMenuItem div#timingBlock label.timing {
    flex-grow: 1;
    font-size: 2rem;
  }

  div#orderMenuItem div#matesBlock {
    font-size: 2rem;
  }

  div#orderMenuItem div#matesBlock label text {
    display: none;
  }

  div#orderMenuItem div#matesBlock img.user_img {
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }

  div#orderMenuItem div.additionsBlock {
    flex-flow: column;
  }

  div#orderMenuItem div.additionsBlock ul {
    width: 100%;
  }

  li.mate img.updown-icon {
    width: 3rem;
    height: 3rem;
  }

  #orderMenuItem .modal-footer button {
    box-shadow: none;
  }
}

@media (min-width: 488px) and (max-width: 1099px) {
  div#orderMenuItem #matesBlock ul li div {
    line-height: 2.5rem;
    height: 5rem;
    min-width: 7rem;
    font-size: 1.5rem;
  }

  div#quantityBlock,
  div#orderMenuItem div#commentBlock,
  div#orderMenuItem div#timingBlock {
    font-size: 2rem;
  }

  div#orderMenuItem div#timingBlock label.timing {
    flex-grow: 1;
    font-size: 2rem;
  }

  div#orderMenuItem div#matesBlock {
    font-size: 2rem;
  }

  div#orderMenuItem div#matesBlock img.user_img {
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }

  div#orderMenuItem div.additionsBlock {
    flex-flow: column;
  }

  div#orderMenuItem div.additionsBlock ul {
    width: 100%;
  }

  div#orderMenuItem .addition-label,
  div#orderMenuItem div.additionsBlock label {
    width: unset;
    height: 4rem;
    font-size: 1.5rem;
  }

  li.mate img.updown-icon {
    width: 3rem;
    width: 3rem;
  }
}

div#orderMenuItem div#quantityBlock,
div#orderMenuItem div#matesBlock,
div#orderMenuItem div#commentBlock,
div#orderMenuItem div#timingBlock {
  margin-right: unset;
  margin-left: 0;
}

@media (max-height: 348px) {
  div#orderMenuItem div#timingBlock {
    margin-right: 34rem;
  }
}

@media (max-height: 401px) {
  div#orderMenuItem div#matesBlock {
    margin-right: 34rem;
  }
}

@media (max-height: 441px) {
  div#orderMenuItem div#quantityBlock {
    margin-right: 34rem;
  }
}

@media (max-height: 478px) {
  div#orderMenuItem div#commentBlock {
    margin-right: 34rem;
  }
}

@media (min-width: 1100px) {
  li.mate img.updown-icon {
    width: 3rem;
    height: 3rem;
  }
}

div#chooseOption .list-group input[type="radio"],
div#orderMenuItem .list-group input[type="radio"] {
  display: none;
}

div#chooseOption .list-group input[type="radio"] + .list-group-item,
div#orderMenuItem .list-group input[type="radio"] + .list-group-item {
  cursor: pointer;
}

div#chooseOption .list-group input[type="radio"] + .list-group-item:before,
div#orderMenuItem .list-group input[type="radio"] + .list-group-item:before {
  color: transparent;
  font-weight: bold;
  margin-right: 1em;
}

div#orderMenuItem label.focus {
  box-shadow: unset;
}

div#orderMenuItem label.active,
div#chooseOption .list-group input[type="radio"]:checked + .list-group-item,
div#orderMenuItem .list-group input[type="radio"]:checked + .list-group-item {
  background-color: #0275d8;
  color: #fff;
}

div#chooseOption .list-group input[type="radio"]:checked + .list-group-item:before,
div#orderMenuItem .list-group input[type="radio"]:checked + .list-group-item:before {
  color: inherit;
}


.modal.settings .modal-dialog {
    width: 100%;
    max-width: unset;
    max-height: calc(100% - 40px);
    max-width: calc(100% - 40px);
    overflow: hidden;
    margin: unset;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    position: relative;
    height: 100%;
}

.modal.settings .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    height: 100%;
    overflow: hidden;
}

.modal.settings .modal-content .modal-body {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.settings .rjsf {
    height: 100%;
    overflow-y: auto;
}
.settings .rjsf {
  margin-left: 1rem;
}

.settings .rjsf .row {
  margin-left: 0;
  margin-right: 0;
}

.settings .rjsf .form-group.field.field-boolean > label {
  display: none;
}

.settings .rjsf .checkbox > label {
  display: flex;
  align-items: center;
}

.settings .rjsf .form-group.field.field-object {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: auto;
}


.settings .rjsf .property-wrapper > .form-group.field.field-object > label,
.settings .rjsf .property-wrapper {
  width: 100%;
}

.settings .rjsf .property {
  margin-right: 1rem;
  flex-basis: 10%;
  flex-grow: 1;
}

.settings .rjsf .form-control {
  min-width: 4rem;
}

.settings .rjsf > .form-group > fieldset,
.settings .rjsf > .form-group > fieldset > .form-group > .form-control {
  min-width: unset;
  flex-grow: 1;
  flex-basis: 100%;
}

.settings .rjsf .form-group.field:not(.field.field-boolean) {
  margin: .5rem;
}

.settings .rjsf .form-group.field.field-object {
  margin:0;
  margin-top:0;
}
.settings .rjsf .form-group.field.field-string textarea {
  width: 60vw;
}
.settings .rjsf .form-group.field.field-number input {
  min-width: 100px;
}
.settings .rjsf .form-group.field.field-string > textarea {
  min-height: 10rem;
}


.settings .rjsf .form-group.field.field-boolean .checkbox {
    margin-top: .5rem;
}

.settings .rjsf .form-group.field.field-boolean .checkbox > label {
  flex-flow: row-reverse;
}

.settings .rjsf .form-group.field.field-boolean,
.settings .rjsf .form-group.field.field-array,
.settings .rjsf .form-group.field.field-string,
.settings .rjsf .form-group.field.field-number {
  display: grid;
  grid-template-columns: 14rem 10rem 2fr;
}

.settings .rjsf .form-group.field.field-boolean > .checkbox,
.settings .rjsf .form-group.field.field-array fieldset.field.field-array.field-array-of-number,
.settings .rjsf .form-group.field.field-array fieldset.field.field-array.field-array-of-string,
.settings .rjsf .form-group.field.field-array fieldset.field.field-array.field-array-of-object {
    grid-column-start: 1;
    grid-column-end: 4;
}

.settings .rjsf .form-group.field.field-array fieldset.field.field-array.field-array-of-object .form-group.field.field-array fieldset.field.field-array.field-array-of-object {
  display: flex;
  flex-flow: column;
}

.settings .rjsf fieldset.field.field-array.field-array-of-number > p,
.settings .rjsf fieldset.field.field-array.field-array-of-string > p,
.settings .rjsf fieldset.field.field-array.field-array-of-object > p,
.settings .rjsf fieldset.field.field-array.field-array-of-number > .row,
.settings .rjsf fieldset.field.field-array.field-array-of-string > .row,
.settings .rjsf fieldset.field.field-array.field-array-of-object > .row {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
}



.settings .rjsf .form-group.field.field-boolean > .checkbox {
    display: grid;
    grid-template-columns: 14rem 10rem 2fr;
}

.settings .rjsf .form-group.field label {
  grid-column-start: 1;
  grid-row-start: 1;
}

.settings .rjsf .form-group.field.field-boolean > .checkbox label {
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 14rem 10rem;
}

.settings .rjsf .form-group.field p {
  margin-left: 1rem;
  grid-column-start: 3;
  grid-row-start: 1;
  display: flex;
}

.settings .rjsf .form-group.field input {
  grid-column-start: 2;
  grid-row-start: 1;
}

.settings .rjsf  .array-item-list {
    width: 100%;
    overflow: hidden;
}

.settings .rjsf .array-item {
    width: 100%;
    display: flex;
    overflow-x: auto;
}

.array-item > .col-xs-9 > .form-group.field.field-object > fieldset > .form-group.field.field-boolean > .checkbox > label {
  display: flex;
  flex-flow: column-reverse;
  height: 100%;
  justify-content: space-between;
}

.array-item > .col-xs-9 > .form-group.field.field-object > fieldset > .form-group.field.field-boolean > .checkbox {
  display: grid;
  grid-template-columns: auto auto 2fr;
}

.settings .rjsf .array-item > div {
    flex-grow: 1;
    overflow: hidden;
}
.settings .rjsf .array-item > div.array-item-toolbox {
    flex-grow: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.settings .rjsf .array-item > div > div > fieldset {
    flex-grow: 1;
    display: flex;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    width: 100%;
    overflow-x: hidden;
    flex-wrap: wrap;

}


.settings .rjsf .array-item > div > div > fieldset .form-group.field {
    display: flex;
    flex-flow: column;
}

.settings .rjsf legend {
    font-size: inherit;
    font-weight: 800;
    margin: .5rem;
}

.settings .rjsf fieldset#root > .field.field-boolean {
    margin: 0;
    padding: 0;
}

.settings .rjsf fieldset#root > .field.field-boolean > .checkbox {
    border: 1px solid lightgray;
    border-radius: .3rem;
    padding: .5rem;
    background-color: rgba(211, 211, 211, 0.338);
    margin-left: .5rem;
    margin-right: .5rem;
}
.settings .rjsf fieldset#root > .field:not(.field-array):not(.field-boolean) {
    border: 1px solid lightgray;
    border-radius: .3rem;
    padding: .5rem;
    background-color: rgba(211, 211, 211, 0.338);
    margin: .5rem;
}

.settings .rjsf fieldset.field-array {
    border: 1px solid lightgray;
    border-radius: .3rem;
    padding: .5rem;
    background-color: rgba(211, 211, 211, 0.338);
    margin-bottom: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
}

.settings .rjsf fieldset.field-array > legend {
    font-size: 1.2rem;
    font-weight: 900;
    background: transparent;
    width: auto;
    padding: .5rem;
}
/*
.settings .rjsf .field-string .form-control {
    min-width: 4rem;
}*/

.settings .rjsf .row {
    justify-content: flex-end;
}

.settings .rjsf .footer {
    border-top: 1px solid lightgray;
    padding-top: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: flex-end;
    height: 4rem;
    position: sticky;
    bottom: 0;
    background-color: white;
}

.settings .rjsf .field > .disabled {
    display:none !important
}

.settings .rjsf .field-description {
    color: gray;
    font-style: italic;
}

#restaurantSuperAdmin .rjsf {
    margin-left: 1rem;
  }
  
  #restaurantSuperAdmin .rjsf .row {
    margin-left: 0;
    margin-right: 0;
  }
  
  #restaurantSuperAdmin .rjsf .form-group.field.field-boolean > label {
    display: none;
  }
  
  #restaurantSuperAdmin .rjsf .checkbox > label {
    display: flex;
    align-items: center;
  }
  
  #restaurantSuperAdmin .rjsf .form-group.field.field-object {
    display: flex;
    flex-wrap: wrap;
  }
  
  #restaurantSuperAdmin .rjsf .property-wrapper > .form-group.field.field-object > label,
  #restaurantSuperAdmin .rjsf .property-wrapper {
    width: 100%;
  }
  
  #restaurantSuperAdmin .rjsf .property {
    margin-right: 1rem;
    flex-basis: 10%;
    flex-grow: 1;
  }
  
  #restaurantSuperAdmin .rjsf .form-control {
    min-width: 4rem;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > fieldset,
  #restaurantSuperAdmin .rjsf > .form-group > fieldset > .form-group > .form-control {
    min-width: unset;
    flex-grow: 1;
    flex-basis: 100%;
  }
  
  #restaurantSuperAdmin .rjsf .form-group.field.field-array {
    width: 90vw;
    max-width: 100%;
  }
  #restaurantSuperAdmin .rjsf .form-group.field.field-string textarea {
    width: 60vw;
  }
  #restaurantSuperAdmin .rjsf .form-group.field.field-number input {
    min-width: 100px;
  }
  #restaurantSuperAdmin .rjsf .form-group.field.field-string > textarea {
    min-height: 10rem;
  }
  
  #restaurantSuperAdmin .rjsf .form-group.field.field-boolean,
  #restaurantSuperAdmin .rjsf .form-group.field.field-integer,
  #restaurantSuperAdmin .rjsf .form-group.field.field-string,
  #restaurantSuperAdmin .rjsf .form-group.field.field-number {
    display: flex;
    margin-left: .5rem;
  }
  #restaurantSuperAdmin .rjsf .form-group.field.field-boolean .checkbox > label {
    flex-flow: row-reverse;
  }
  #restaurantSuperAdmin .rjsf .form-group.field.field-boolean .checkbox > label > span,
  #restaurantSuperAdmin .rjsf .form-group.field.field-integer > label,
  #restaurantSuperAdmin .rjsf .form-group.field.field-string > label,
  #restaurantSuperAdmin .rjsf .form-group.field.field-number > label {
    min-width: 10rem;
    margin-right: 1rem;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property,
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper {
    margin-left: 1rem;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > .property {
    margin-left: 2rem;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > .property,
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > .property-wrapper {
    margin-left: 2rem;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > .property {
    margin-left: 3rem;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > label {
    background-color: rgb(92, 248, 234);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    font-variant: small-caps;
    font-size: 140%;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > label {
    background-color: rgb(150, 248, 234);
    position: sticky;
    top: 2rem;
    left: 1rem;
    z-index: 9;
    font-variant: small-caps;
    font-size: 130%;
  }
  
  #restaurantSuperAdmin .rjsf > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > .property-wrapper > .form-group > label {
    background-color: rgb(200, 248, 234);
    position: sticky;
    top: 3.9rem;
    left: 2rem;
    z-index: 8;
    font-variant: small-caps;
    font-size: 120%;
  }
  
  #restaurantSuperAdmin .rjsf .field-description {
    color: gray;
    font-style: italic;
    min-width: 20rem;
  }

  .settings .rjsf fieldset#root > .field:not(.field-array):not(.field-boolean) {
    display: grid;

  }
  
  #restaurantSuperAdmin .rjsf .form-group.field > .checkbox {
    flex-flow: row-reverse;
    display: flex;
    flex-wrap: nowrap;
  }
  
  #restaurantSuperAdmin .rjsf .field-description {
    margin-left: 1rem;
  }
  
  #restaurantSuperAdmin .rjsf label[for = "root_description_header"] + .property + .property,
  #restaurantSuperAdmin .rjsf label[for = "root_description_header"] + .property,
  #restaurantSuperAdmin .rjsf label[for = "root_description_footer"] + .property + .property,
  #restaurantSuperAdmin .rjsf label[for = "root_description_footer"] + .property,
  #restaurantSuperAdmin .rjsf label[for = "root_description"] + .property + .property,
  #restaurantSuperAdmin .rjsf label[for = "root_description"] + .property {
    min-width: 100%;;
  }

  .btn-group, 
  .btn-group-vertical {
    flex-wrap: wrap;
  }

  .rjsf .tab-content > div > .form-group.field > label {
    display: none;
  }

  .rjsf .tabbed-object-field-template > legend  {
    display: none;
  }

  .rjsf .tabbed-object-field-template .tab-content {
    padding: 10px;
    border: 1px solid lightgray
  }

  .rjsf .tabbed-object-field-template > .tabs > button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rjsf .tabbed-object-field-template > .tabs > button.btn-info {
    background-color: transparent;
  }

  .rjsf [type="submit"] {
    display: none;
  }
  
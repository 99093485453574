table.orderTable.list-style .menuItem-price {
  font-size: 2em;
}

table.orderTable .style1 {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 1px;
}

table.orderTable tr.newOrder td.state .order::before {
  /*animation: pulse4 1s infinite steps(5);*/
}

@keyframes pulse4 {
  0% {
  }
  50% {
    fill: yellow;
    background: linear-gradient(to bottom, yellow 0%, yellow 95%, orange 95%, orange 100%);
  }
  100% {
  }
}


table.orderTable tr.pending td.state .order {
 box-shadow: unset;
}

table.orderTable .style2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

table.orderTable .style3 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

table.orderTable .style4 {
  flex-grow: 1;
  position: relative;
}

table.orderTable .style5 {
  text-decoration: line-through;
  color: red;
}

table.orderTable .style6 {
  padding-left: 3rem;
  text-align: center;
  border-left: unset;
  border-right: unset;
}

table.orderTable .style7 {
  border-left: unset;
  display: flex;
  padding-top: 1rem !important;
  justify-content: space-between;
  height: 100%;
}

.pointOfSale table.orderTable .style7 {
  justify-content: flex-end;
}


table.orderTable .style8 {
  vertical-align: top;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-right: unset;
  display: none;
}

table.orderTable .style9 {
  text-align: center;
  border-left: unset;
}

table.orderTable .style9 .tableNumber {
  display: none;
}

table.orderTable tr[timing = "asap-"].orderrow td {
  background-color: #ffe4c482;
}


table.orderTable tr[timing = "appetizer-"].orderrow td {
  background-color: #f6b05ca2;
}

table.orderTable tr[timing = "maincourse-"].orderrow td {
  background-color: #dff170a2;
}

table.orderTable tr[timing = "desert-"].orderrow td {
  background-color: #7073f19c;
}

table.orderTable tr[timing = "last-"].orderrow td {
  background-color: #f19d70a2;
}

table.orderTable .style10 {
  position: relative;
  text-align: left;
  z-index: 100;
  overflow: visible;
  white-space: nowrap;
  top: 1rem;
  left: 0.5rem;
  color: #d69f5d;
  font-weight: bolder;
}

table.orderTable .style11 {
  display: flex;
  align-items: stretch;
  height: 100%;
}

table.orderTable .style12 {
  width: 2rem;
  height: 2rem;
}

table.orderTable .style13 {
  border: unset;
  height: 1rem;
}

table.orderTable .style14 {
  text-align: center;
  font-size: 1.2rem;
  background: inherit;
  max-width: 0 !important;
  width: 0px !important;
}

table.orderTable col[state = 'orderSequenceNumber'],
table.orderTable th.orderSequenceNumber,
table.orderTable td.orderSequenceNumber {
  display:none !important;
}

table.orderTable .style15 {
}

table.orderTable .style16 {
}

table.orderTable .style17 {
}

table.orderTable .style18 {
}

table.orderTable td.menuItem-price {
  padding: 0;
  text-align: right;
  padding-right: 3rem;
}

table.orderTable tf.orderSequenceNumber {
  width: 0px;
  border-left: unset;
  border-right: unset;
}
